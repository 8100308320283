<template>
  <div v-if="$route.meta.layout">
    <!-- Pour afficher le contenu de la page actuelle -->
    <component :is="$route.meta.layout">
      <router-view />
      <loading-indicator />
      <!-- Pour afficher le contenu de la page spécifique au modèle de mise en page -->
    </component>
  </div>
  <div v-else>
    <Navbar />
    <router-view />
    <loading-indicator />
    <!-- Pour afficher le contenu de la page actuelle -->

    <Footer />
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
//import HelloWorld from './components/HelloWorld.vue';
import Footer from './components/Footer.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';


export default {
  name: 'App',
  components: {
    //HelloWorld,
    Navbar,
    Footer,
    LoadingIndicator  
  }
  
}
</script>

<style>


</style>
