<template>
  <footer class="text-white bg-green-400" >
    <div class="container">
      <!-- Section de contact -->
      <div class="text-center">
        <div class="h-4"></div>
        <img src="../assets/LOG0.png" alt="Logo" class="h-24 w-44 mx-auto" />
        <p class="text-xl mt-4 leading-5">
          Connectez-vous à vos auteurs préférés et voyagez à travers les pages
          d'histoires
        </p>
        <h1
          class="text-4xl font-bold border border-gray-200 mt-4 lg:mx-24 mx-16 block lg:hidden opacity-25"
        ></h1>
        <ul
          class="lg:flex lg:space-x-6 lg:text-lg justify-center text-center mt-4"
        >
          <li @click="toggDropdown">
            <router-link
              class="text-yellow-400 hover:text-gray-300 hover:text-yellow-400 hover:underline"
              to="/"
              >Accueil</router-link
            >
          </li>
          <li @click="toggDropdown">
            <router-link
              class="text-yellow-400 hover:text-gray-300 hover:text-yellow-400 hover:underline"
              to="/"
              >A propos</router-link
            >
          </li>
          <li @click="toggDropdown">
            <router-link
              class="text-yellow-400 hover:text-gray-300 hover:text-yellow-400 hover:underline"
              to="/"
              >Nous contacter</router-link
            >
          </li>
          
        </ul>
        <ul
          class="flex lg:space-x-6 lg:text-xl font-bold justify-center text-center mt-4"
        >
          <li @click="toggDropdown">
            <router-link class="flex text-white hover:text-gray-300" to="/"
              >CGU
              <h1 class="ml-4">.</h1></router-link
            >
          </li>
          <li @click="toggDropdown">
            <router-link class="flex text-white hover:text-gray-300" to="/"
              >FAQ
              <h1 class="ml-4">.</h1></router-link
            >
          </li>
          <li @click="toggDropdown">
            <router-link class="flex text-white hover:text-gray-300" to="/"
              >Politique de confidentialité
            </router-link>
          </li>
        </ul>
        <h1
          class="text-4xl font-bold border border-white mt-4 lg:mx-24 mx-6 opacity-50"
        ></h1>
        <div class="lg:flex lg:mx-24">
          <div class="lg:w-1/3">
            <p class="text-xl text-yellow-400 mt-4 lg:text-left">Newsletter</p>

            <div class="flex mt-2 justify-center md:justify-start">
              <input
                type="text"
                v-model="filterse"
                placeholder="Entrer votre e-mail"
                class="rounded-l-sm border border-blue-400"
              />
              <button
                type="submit"
                class="bg-blue-400 text-white rounded-r px-2 md:px-3 py-0 md:py-1"
              >
                Valider
              </button>
            </div>
          </div>
          <div class="lg:w-1/3">
            <p class="text-xl text-yellow-400 mt-4 leading-5">Suvez-nous</p>
            <div class="flex justify-center mt-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                class="bi bi-linkedin"
                viewBox="0 0 16 16"
              >
                <path
                  d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                class="bi bi-tiktok ml-4"
                viewBox="0 0 16 16"
              >
                <path
                  d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                class="bi bi-facebook ml-4"
                viewBox="0 0 16 16"
              >
                <path
                  d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"
                />
              </svg>
            </div>
          </div>
          <h1
            class="text-4xl font-bold border border-white mt-4 lg:mx-24 mx-6 block lg:hidden"
          ></h1>
          <div class=" ">
            <p
              class="lg:text-xl text-sm lg:mt-12 mt-4 lg:text-right whitespace-nowrap lg:font-bold"
            >
              Copyright@ 2024 Digitalis. Tous droits réservés
            </p>
          </div>
        </div>
        <div class="h-6"></div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      isDropdownOpen: false,
      isDropdownOpens: false,
      isMobileMenuOpen: false,
      isDropdownOpenPrfile: false,
      isOpen: false,
      user: "",
      name: "",
      imageUrl: "",
    };
  },
  methods: {
    contactezNous() {
      // Ouvrir un client de messagerie avec l'adresse e-mail pré-remplie
      window.location.href = "mailto:support@csetrainings.com";
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      this.isDropdownOpens = false;
      this.isDropdownOpenPrfile = false;
    },
    ClotoggleDropdown(){
      this.isDropdownOpen= false
    }
  },
};
</script>
